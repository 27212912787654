import React from 'react';
import Link from 'next/link';

const BranchInfo = ({ name, address, phone, email }) => (
  <div className="form-login-address">
    <span className="branch-address-title">{name}</span> {' '}
    <br />
    <div className="branch-address">{address}</div>
    <Link href={`tel:${phone}`}>{phone}</Link> <span className='separator'>|{' '}</span>
    <Link href={`mailto:${email}`}>{email}</Link>
  </div>
);

export default BranchInfo;
