import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { Alert, Input, Button, Form, Divider, message } from 'antd';
import Head from '../components/layout/head';
import { userSignIn, userSignInSuccess, clearError } from '../actions';
import redirectByRole from '../components/common/RedirectByRole';
import Link from 'next/link';
import { ROLES } from '../constants/AuthConstants';
import BranchInfo from '../components/login/branchInfo';

const FormItem = Form.Item;

class Login extends React.Component {

  componentDidUpdate(prevProps) {
    const { error, t } = this.props;
    if (error && error !== prevProps.error) {

      message.error(t(error));
      this.props.clearError();
    }
  }

  render() {
    const { t, error } = this.props;
    const currentYear = new Date().getFullYear();
    const branches = [
      { id: 1, name: t('branch1-name'), address: t('branch1-address'), phone: t('branch1-phone'), email: t('branch1-email') },
      { id: 2, name: t('branch2-name'), address: t('branch2-address'), phone: t('branch2-phone'), email: t('branch2-email') },
      { id: 3, name: t('branch3-name'), address: t('branch3-address'), phone: t('branch3-phone'), email: t('branch3-email') },
    ];

    return (
      <>
        <Head title={`${t('Iturria SA')} - ${t('login')}`} />

        <div className="login-layout">
          <div className="login-container">
            <div className="centered-form-and-image">
              <div className="form-and-image-container">
                <div className="computer-image-container">
                  <img src="/static/laptop_login.png" className="computer-image" />
                </div>
                <div className="login-form">
                  <div className="form-header">
                    <div className="login-logo-container">
                      <img src="/static/logo-login.png" />
                    </div>
                    <div>
                      <div className="login-title">{t('WelcomeToOur')}</div>
                      <div className="login-subtitle">{t('digital-catalog')}</div>
                    </div>
                  </div>
                  <div className="login-form-content">
                    {error && <Alert message={t(error)} type="error" />}
                    <Formik
                      initialValues={{ username: '', password: '' }}
                      onSubmit={(values, actions) => {
                        this.props.clearError();
                        this.props.userSignIn(values);
                        actions.setSubmitting(false);
                      }}
                      validationSchema={Yup.object().shape({
                        username: Yup.string().required(
                          t('validation-required', {
                            field: t('username'),
                          })
                        ),
                        password: Yup.string().required(
                          t('validation-required', {
                            field: t('password'),
                          })
                        ),
                      })}
                    >
                      {({
                        values,
                        handleBlur,
                        handleChange,
                        errors,
                        touched,
                        isSubmitting,
                        isValid,
                        handleSubmit,
                      }) => (
                        <Form onFinish={handleSubmit}>
                          <FormItem
                            className={
                              errors.username && touched.username
                                ? 'has-error'
                                : ''
                            }
                          >
                            <label className="login-input-label">
                              <span>{t('insertYour')}</span>
                              <span className="input-label-bold">
                                {t('username')}:
                              </span>
                            </label>
                            <Input
                              id="username"
                              type="text"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.username && touched.username && (
                              <div className="ant-form-explain has-error">
                                {errors.username}
                              </div>
                            )}
                          </FormItem>
                          <FormItem
                            className={
                              errors.password && touched.password
                                ? 'has-error'
                                : ''
                            }
                          >
                            <label className="login-input-label">
                              <span>{t('insertYour')}</span>
                              <span className="input-label-bold">
                                {t('password')}:
                              </span>
                            </label>
                            <Input
                              id="password"
                              type="password"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder={t('password')}
                            />
                            {errors.password && touched.password && (
                              <div className="ant-form-explain has-error">
                                {errors.password}
                              </div>
                            )}
                          </FormItem>
                          <Button
                            className="login-form-button"
                            htmlType="submit"
                            disabled={!isValid || isSubmitting}
                          >
                            {t('login')}
                          </Button>
                          <div className="form-login-footer">
                            <Link href="/password-recovery">
                              <a className="login-form-action">
                                {t('password-recovery')}
                              </a>
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="login-footer">
            <div className="footer-branch-container">
              {branches.map((branch, index) => (
                <>
                  <BranchInfo key={branch.id} {...branch} />
                  {index < branches.length - 1 && <Divider type="vertical" className="footer-divider" />}
                </>
              ))}
            </div>
            <div className="form-login-url">
              <a href="http://www.iturria.com.ar/">Iturria</a>
              {`${t('footer-text')} ${currentYear}`}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ common, auth }) => {
  const { error } = common;
  const { userId, user, token } = auth;
  return { error, userId, user, token };
};

export default connect(mapStateToProps, {
  userSignIn,
  userSignInSuccess,
  clearError,
})(withTranslation()(redirectByRole(withRouter(Login), [ROLES.UNAUTHENTICATED])));
